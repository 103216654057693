.new-rankings-dialog {
    .dialog-title {
        border-bottom: 1px solid var(--lightgrey);
        padding: 14px;
    }
    h2 {
        font-size: 1.2rem;
        font-weight: 600;
    }
    .description {
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .dialog-input {
        padding-bottom: 1.2rem;

        label {
            display: block;
            font-size: 0.9rem;
            font-weight: 600;
            color: var(--dark-grey);
            margin-bottom: 3px;
        }
        .dialog-input-select, .dialog-input-text {
            width: 100%;
        }
    }
    .dialog-actions {
        border-top: 1px solid var(--lightgrey);
        padding: 1rem;
    }
    .submit-btn, .cancel-btn {
        cursor: pointer;
        border: none;
        padding: 0.7rem;
        border-radius: 4px;
        font-weight: 600;
        font-size: 0.8rem;
    }
    .submit-btn {
        background-color: var(--submit-btn-light-1);
        color: white;
    }
    .submit-btn:hover {
        background-color: var(--submit-btn-hover-light-1);
    }
    .cancel-btn {
        background-color: var(--lightgrey);
    }
}
.MuiDialog-paperWidthMd {
    width: 400px;
}