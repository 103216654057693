.auth-form {
    margin: auto;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    box-shadow: var(--box-shadow-1);
    border-radius: 4px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;

    .error-message-wrapper {
        display: flex;
        align-items: center;
        color: red;
        font-size: 11pt;
        padding-left: 10px;
        
        .error-icon {
            margin-right: 6px;
        }
    }

    .loading-spinner {
        margin: -20px;
        padding: -20px;
    }

    h2 {
        font-weight: 600;
        font-size: 20pt;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 20px;
    }
    hr {
        margin: 10px;
        border: 0.5px solid lightgrey;
        box-shadow: none;
    }
    .text-input, .google-login-btn {
        margin: 10px;
        background-color: white;
    }
    .text-input label {
        font-size: 10pt;
    }
    .google-auth-wrapper {
        display: flex;
        justify-content: center;
    }
    .submit-btn {
        background-color: var(--btn-blue-1);
        color: white;
        border-radius: 4px;
        padding: 12px;
        border: none;
        font-size: 11pt;
        font-weight: 600;
        cursor: pointer;
        margin: 10px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;

        div {
            height: 50%;
            display: flex;

            div {
                top: 0;
                height: 10px;
                width: 10px;
                font-size: 8pt;
            }
        }
    }
    .submit-btn:hover {
        background-color: var(--btn-blue-hover-1);
    }
    .link-wrapper {
        padding: 20px;
        font-size: 12pt;
        text-align: center;

        a {
            border: None;
            color: var(--link-color-1);
            background: none;
            font-weight: 400;
            cursor: pointer;
            text-decoration: None;
            margin-left: 5px;
        }
        a:hover {
            color: var(--link-color-hover-1);
        }
    }
}

@media (max-width: 540px) {
	.auth-form {
		box-shadow: none;
        margin-top: 5vh;
        background-color: var(--offwhite-1);
	}
}