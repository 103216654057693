.draft-board-column-header {
    min-width: 100px;
    min-height: 40px;
    max-width: 120px;
    max-height: 50px;
    display: inline-block;
    margin: 0.2rem;
    padding: 0.2rem;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}