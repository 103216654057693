.account-details-wrapper {
    box-shadow: var(--box-shadow-1);
    border-radius: 0.4rem;

    .account-detail:first-child {
        border-radius: 0.4rem 0.4rem 0 0;
    }

    .account-detail:last-child {
        border-radius: 0 0 0.6rem 0.6rem;
        border-bottom: none;
    }

    .account-detail-inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        .label {
            font-weight: 600;
            margin-bottom: 0.9rem;
        }

        .value {
            margin-left: 0.5em;
        }

        .label,
        .value {
            font-size: 1rem;
        }
    }

    .edit-icon {
        color: var(--link-color-1);
        cursor: pointer;
    }

    .edit-icon:hover {
        color: var(--link-color-hover-1);
    }

    .account-detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        padding: 1em;
        border-bottom: 1px solid var(--lightgrey);
    }

    .confirm-buttons-wrapper {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;
    }

    .account-changes-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        .account-changes-inner {
            display: flex;
            flex-direction: column;
            width: min(400px, 100%);
        }
    }

    .confirm-wrapper {
        display: flex;
    }

    .text-input {
        margin: 0.5em !important;
    }

    .cancel-btn,
    .save-btn {
        border: none;
        padding: 0.7rem;
        border-radius: 0.4rem;
        font-weight: 600;
        font-size: 0.8rem;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
    }

    .cancel-btn {
        color: var(--link-color-1);
        background-color: var(--lightgrey);
    }

    .save-btn {
        background-color: var(--btn-blue-1);
        color: white;
    }

    .save-btn:hover {
        background-color: var(--btn-blue-hover-1);
    }
    .error-message-wrapper {
        display: flex;
        align-items: center;
        color: red;
        font-size: 0.9rem;
        padding-left: 0.5rem;
        margin-bottom: 0.5rem;
        
        .error-icon {
            margin-right: 0.4rem;
        }
    }
}