.draftable,
.tier {
    user-select: none;
    padding: 0.7rem;
    font-size: 10pt;

    .drag-icon,
    .delete-icon {
        font-size: 1.5rem;
    }

    .delete-icon {
        color: var(--red-1);
    }

    .delete-icon:hover {
        cursor: pointer;
    }
}

.draftable,
.tier:not(.top-tier) {
    cursor: grab;
}

.draftable,
.tier,
.table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div:not(.name-wrapper) {
        display: flex;
        align-items: center;
    }
}

.draftable,
.table-header {
    background-color: white;
    border-top: 1px solid var(--lightgrey);
    border-left: 1px solid var(--lightgrey);
    border-right: 1px solid var(--lightgrey);
    border-bottom: 1px solid var(--lightgrey);
    margin-bottom: -1px;

    .drag-icon-wrapper {
        flex: 1;
        justify-content: flex-end;
    }

    .rank-wrapper {
        flex: 1;
        justify-content: flex-start;

        .rank-input {
            width: 50%;
        }
        .rank-input.unselected {
            border: none;
        }
    }

    .bye-wrapper {
        flex: 2;
        justify-content: center;
    }

    .name-wrapper {
        display: flex;
        flex: 4;
        justify-content: flex-start;
        font-weight: 600;
        flex-wrap: wrap;

        span {
            margin-right: 4px;
        }
    }

    .team-wrapper {
        flex: 2;
        justify-content: center;
    }

    .position-wrapper {
        flex: 2;
        justify-content: center;
    }

    .buttons-wrapper {
        flex: 2;
        justify-content: flex-end;
    }

    .delete-icon-wrapper {
        flex: 1;
        justify-content: flex-start;
    }

    .delete-icon {
        color: var(--red-1);
    }

    .drag-icon {
        color: var(--grey);
    }
}

.tier {
    background-color: var(--table-header-color);
    border-top: 1px solid var(--lightgrey);
    border-left: 1px solid var(--lightgrey);
    border-right: 1px solid var(--lightgrey);
    border-bottom: 1px solid var(--lightgrey);
    color: black;
    margin-bottom: -1px;

    .drag-icon-wrapper {
        flex: 1;
        justify-content: flex-end;
    }

    .rank-wrapper {
        flex: 2;
        justify-content: center;
        font-weight: 700;
    }

    .buttons-wrapper {
        flex: 1;
        justify-content: flex-start;
    }
}

.insert-tier-btn {
    color: white;
    background: var(--btn-blue-1);
    border: none;
    padding: 3px 8px 3px 8px;
    font-size: 9pt;
    font-weight: 600;
    display: flex;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;

    .insert-icon {
        margin-right: 8px;
    }
}

@media (min-width: 499px) {

    .draftable,
    .table-header {
        .name-wrapper {
            flex-direction: row;
            align-items: center;
        }
    }
}

@media (max-width: 500px) {

    .draftable, .tier {
        padding: 0.7rem;
    }

    .draftable,
    .table-header {
        font-size: 0.9rem;

        .rank-wrapper {
            flex: 2;
            justify-content: center;
        }

        .name-wrapper {
            flex-direction: column;
            flex: 4;
            justify-content: flex-start;
        }

        .delete-icon-wrapper {
            justify-content: center;
        }

        .buttons-wrapper {
            flex: 1;
        }
    }
    .tier {
        .rank-wrapper {
            flex: 12;
            justify-content: center;
        }
        .buttons-wrapper {
            justify-content: center;
        }
    }
}

.draftable.selected {
    background-color: var(--table-header-color);
}

.tier.selected {
    background-color: #dfe4f2;
}

@media (pointer: fine), (pointer: none) {
	.draftable:hover {
        background-color: var(--table-header-color);
    }
    .tier:not(.top-tier):hover {
        background-color: #dfe4f2;
    }
}