.position-filter {
    display: flex;

    button {
        font-size: 0.9rem;
        background-color: white;
        padding: 0.6rem 0.9rem 0.6rem 0.9rem;
        border-top: 1px solid var(--lightgrey);
        border-bottom: 1px solid var(--lightgrey);
        border-right: 1px solid var(--lightgrey);
        cursor: pointer;
        font-weight: 600;
        color: var(--btn-blue-1);
    }

    button:hover {
        background-color: var(--table-header-color);
    }

    :nth-child(1):not(.active) {
        border-left: 1px solid var(--lightgrey);
        border-radius: 6px 0 0 6px;
    }

    :nth-child(1):is(.active) {
        border-left: 1px solid var(--btn-blue-1);
        border-radius: 6px 0 0 6px;
    }

    :nth-child(n + 2) {
        border-left: none;
    }

    :last-child {
        border-radius: 0 0.4rem 0.4rem 0;
    }

    button.active {
        background-color: var(--btn-blue-1);
        color: var(--text-color-2);
        border-top: 1px solid var(--btn-blue-1);
        border-bottom: 1px solid var(--btn-blue-1);
        border-right: 1px solid var(--btn-blue-1);
    }
}

.position-filter-small {
    select {
        cursor: pointer;
        border: 1px solid var(--lightgrey);
        border-radius: 6px;
        background-color: white;
        
        div {
            font-size: 0.5rem !important;
        }
    }
}