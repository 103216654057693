.add-players-list {
    border: 1px solid var(--lightgrey);
    border-radius: 6px;
    margin-top: 6px;
    background-color: white;

    .add-player {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.6rem;
        min-height: 30px;
        background-color: white;
        border-bottom: 1px solid var(--lightgrey);
    }

    .add-player:last-child {
        border-bottom: none;
    }

    .add-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;

        .add-icon {
            font-size: 1.8rem;
            cursor: pointer;
            color: var(--btn-blue-1);
        }
    }

    .info-wrapper {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.8rem;
        user-select: none;

        .name-wrapper {
            display: flex;
            align-items: center;
            font-weight: 600;
        }
    }

    .details {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .team-wrapper {
        margin-right: 0.5rem;
    }

    .empty-players-indicator {
        margin-left: auto;
        margin-right: auto;
        font-weight: 500;
        font-size: 0.9rem;
    }
}

.add-players-list:not(.small) {
    max-height: 80vh;
    overflow-y: auto;
    border-collapse: separate;
}

.add-players-list.small {
    .add-player:first-child {
        border-radius: 6px 6px 0 0;
    }

    .add-player:last-child {
        border-radius: 0 0 6px 6px;
    }

    .add-player:only-child {
        border-radius: 6px;
    }
}