.draft-board-square {
    min-width: 100px;
    min-height: 40px;
    max-width: 120px;
    max-height: 50px;
    margin: 0.2rem;
    background-color: var(--lightgrey);
    padding: 0.2rem;
    border-radius: 4px;

    p {
        font-size: 0.7rem;
    }
}