footer {
    background-color: var(--black);
    margin-top: 20px;

    .footer-inner {
        display: flex;
        padding: 30px 30px 50px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-logo {
        width: 170px;
        max-width: 30vw;
        cursor: pointer;
    }

    .footer-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }

    .footer-link {
        text-decoration: none;
        font-size: 1rem;
        margin: 0.6rem;
        color: white;
        font-weight: 600;
        color: var(--table-header-color);
    }
    .footer-link:hover {
        color: var(--lightgrey);
    }
}