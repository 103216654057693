.custom-rankings-page {

    h1 {
        margin-top: 0.6rem;
    }

    .login-link {
        color: var(--btn-blue-1);
        cursor: pointer;
        text-decoration: none;
    }

    .login-indicator {
        margin-top: 4rem;
        text-align: center;
        font-size: 1rem;
    }

    .login-link:hover {
        color: var(--btn-blue-hover-1);
    }

    .rankings-options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .description {
        margin-left: 3px;
        margin-top: 10px;
        color: black;
    }

    .edit-button {
        border: none;
        background-color: var(--btn-blue-1);
        color: white;
        cursor: pointer;
        padding: 0.7rem 0rem 0.7rem 0.9rem;
        border-radius: 6px;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 600;
        box-shadow: var(--box-shadow-1);

        .new-icon {
            padding-left: 0.2rem;
            padding-right: 0.8rem;
            transition: 0.2s padding ease-in-out;
        }
    }

    .edit-button:hover {
        background-color: var(--submit-btn-hover-light-1);

        .new-icon {
            padding-left: 0.4rem;
            padding-right: 0.6rem;
        }
    }
}
