.account-page {

    .title-wrapper {
        margin-top: 1.4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .logout-btn {
        padding: 0.6rem 0.8rem 0.6rem 0.8rem;
        background-color: var(--lightgrey);
        border: none;
        border-radius: 0.4rem;
        cursor: pointer;
        color: var(--link-color-1);
        font-weight: 600;
        text-decoration: none;
        font-size: 0.9rem;
    }
    .logout-btn:hover {
        color: var(--link-color-hover-1)
    }
    
    .delete-account-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.6rem;
    }
    .delete-account-btn {
        margin: auto;
        padding: 0.7rem;
        font-weight: 600;
        font-size: 0.9rem;
        color: var(--red-1);
        border: none;
        border-radius: 0.4rem;
        background-color: var(--lightgrey);
        cursor: pointer;
    }

    .delete-account-btn:hover {
        color: var(--link-color-hover-1);

    }
}