.rankings-table {
    border-spacing: 0;
    background-color: white;
    box-shadow: var(--box-shadow-1);

    thead tr {
        background-color: var(--lightgrey);
    }
    .name {
        font-weight: 600;
    }
    tbody td, thead th {
        padding: 0.6rem;
        font-size: 10pt;
    }
    tr {
        display: flex;

        td, th {
            flex: 1;
            text-align: center;
        }
        .name, .name-header {
            flex: 3;
            justify-content: flex-start;
            text-align: left;
        }
    }
    tbody tr:nth-child(even) {
        background-color: #FAFAFA;
    }
    th {
        cursor: pointer;
        user-select: none;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .info-icon {
        height: 0.8em;
        color: var(--grey);
    }
    tbody tr:hover {
        background-color: var(--table-header-color);
    }
    @media (max-width: 499px) {
        tbody td, thead th {
            padding: 0.6rempx;
            font-size: 9pt;
        }
        tr {
            .name, .name-header {
                flex: 4;
            }
        }
    }
}