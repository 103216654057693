.default-nav {
    background-color: var(--nav-color);
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 2;

    nav {
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1300px;
    }

    img {
        height: 30px;
        cursor: pointer;
        padding: 15px;
    }

    .nav-links {
        display: flex;
        align-items: center;
    }

    .nav-link {
        cursor: pointer;
        text-decoration: none;
        color: var(--text-color-2);
        margin-right: 25px;
        border: none;
        background: none;
        font-size: 0.9em;
        font-weight: 600;
    }

    .nav-link:hover {
        color: var(--lightgrey);
    }

    .nav-link-outline {
        border: 1px solid white;
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
    }

    .nav-link-outline:hover {
        border: 1px solid var(--lightgrey);
    }

    .profile-dropdown {
        position: relative;
        display: inline-block;

        .hidden {
            display: none;
        }

        .dropdown-content {
            display: block;
            position: absolute;
            right: 0;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1000;
            border-radius: 6px;

            :first-child {
                border-radius: 6px 6px 0px 0px;
            }

            :last-child {
                border-radius: 0px 0px 6px 6px;
            }

            .profile-dropdown-link, p {
                color: black;
                text-decoration: none;
                font-size: 11pt;
                color: black;
                padding: 1.1em;
                font-weight: 700;
                border: none;
                border-bottom: 1px solid var(--lightgrey);
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: white;
            }

            button.profile-dropdown-link {
                width: 100%;
            }

            .user-email {
                font-weight: 600;
                border-bottom: 1px solid var(--lightgrey);
            }

            .profile-dropdown-link:hover {
                background-color: var(--table-header-color);
            }
        }

        .user-icon {
            padding: 6px;
            border: 2px solid white;
            background: none;
            border-radius: 50%;
            color: white;
            cursor: pointer;
        }
    }
}
.default-nav.shadow {
    box-shadow: 0 10px 10px -10px var(--grey);
    // border-bottom: 2px solid var(--lightgrey);
}