.custom-rankings-list {
    margin: auto;

    .row {
        height: 74px;
        background-color: white;
        margin-bottom: 0.5rem;
        border: 1px solid var(--lightgrey);
        border-radius: 6px;
    }

    .row,
    .col-labels {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rankings-title {
        color: var(--btn-blue-1);
        font-weight: 600;
        padding: 2px;
        cursor: pointer;
        width: fit-content;
    }

    .rankings-title:hover {
        color: var(--btn-blue-hover-1);
    }

    .rankings-details {
        display: flex;
        color: var(--grey);
        font-size: 9pt;
        padding: 2px;

        p {
            margin-right: 10px;
        }
    }

    .rankings-title-wrapper {
        flex: 2;
        margin-left: 2%;
    }

    .rankings-options-btn {
        padding: 5px;
        height: 1.2em;
        width: 1.2em;
        border-radius: 50%;
    }

    .rankings-options-btn:hover {
        background-color: var(--lightgrey);
        border-radius: 50%;
    }

    .last-update {
        font-size: 10pt;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        margin-left: 1%;
        margin-right: 2%;
        cursor: pointer;

        .hidden {
            display: none;
        }

        .dropdown-content {
            display: block;
            position: absolute;
            left: 0;
            background-color: white;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            border-radius: 6px;

            :first-child {
                border-radius: 6px 6px 0px 0px;
            }

            :last-child {
                border-radius: 0px 0px 6px 6px;
            }

            button {
                color: black;
                padding: 10px 12px;
                text-decoration: none;
                display: block;
                font-size: 11pt;
                width: 100%;
                border: None;
                cursor: pointer;
                background-color: white;
                text-align: left;
            }

            button:hover {
                background-color: var(--table-header-color);
            }
        }

        .dropbtn {
            padding: 6px;
            border: 2px solid white;
            background: none;
            border-radius: 50%;
            color: white;
            height: 34px;
            width: 34px;
            cursor: pointer;
        }
    }

    .sport-icon-wrapper {
        background-color: var(--grey);
        height: 50%;
        display: flex;
        aspect-ratio: 1;
        border-radius: 4px;
        justify-content: center;
        align-items: center;

        .sport-icon {
            font-size: 16pt;
            color: white;
        }
    }

    .last-update {
        margin-right: 4px;
    }

    .col-labels {

        .last-update {
            color: var(--grey);
        }
    }

    @media (max-width: 499px) {
        .sport-icon-wrapper {
            height: 40%;

            .sport-icon {
                font-size: 12pt;
            }
        }

        .dropdown {
            margin-right: 1%;
        }

        .rankings-title {
            font-size: 11pt;
        }

        .last-update {
            font-size: 9pt;
        }
    }
}