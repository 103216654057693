.mobile-nav {
    width: 100%;
    background-color: var(--nav-color);
    position: sticky;
    top: 0;
    z-index: 2;

    .logo {
        cursor: pointer;
    }

    * {
        margin: 0;
        box-sizing: 0;
    }

    nav {
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
    }

    .burger-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        cursor: pointer;
    }

    .burger-bar {
        width: 1.5em;
        height: 0.25em;
        background-color: var(--text-color-2);
        border-radius: 0.5em;
    }

    .menu {
        width: 100%;
        background-color: black;
        position: absolute;
        z-index: 3;
        box-shadow: 0 10px 20px -10px var(--grey);
    }

    .hidden {
        display: none;
    }

    .visible {
        display: inherit;
    }

    .burger-bar.clicked:nth-child(1) {
        transform: rotate(45deg) translate(0.5em, 0.375em);
        transition: ease-out 0.2s;
    }

    .burger-bar.clicked:nth-child(2) {
        transform: scale(0.0);
        transition: ease-out 0.2s;
    }

    .burger-bar.clicked:nth-child(3) {
        transform: rotate(135deg) translate(-0.5em, 0.375em);
        transition: ease-out 0.2s;
    }

    .burger-bar.unclicked {
        transform: rotate(0) translate(0);
        transition: ease-out 0.2s;
    }

    .mobile-nav-link {
        color: white;
        padding: 1.4em;
        font-weight: 700;
        border: none;
        border-top: 1px solid var(--grey);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
    }
}

.mobile-nav.shadow {
    box-shadow: 0 10px 20px -10px var(--grey);
}