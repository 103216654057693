.edit-ranking-page {
    width: 100%;

    main {
        width: 1200px;
    }

    .login-link-wrapper {
        color: black;
        margin-bottom: 0.8rem;
    }

    .login-link {
        color: var(--link-color-1);
        cursor: pointer;
        text-decoration: none;
    }

    .login-link:hover {
        color: var(--link-color-hover-1);
    }

    .columns-wrapper {
        display: flex;
        flex-direction: row;

        h3 {
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
            font-size: 1.4rem;
            font-weight: 600;
        }

        .rankings-wrapper {
            flex: 3 1 500px;
        }

        .add-players-wrapper {
            margin-left: 10px;
            flex: 1 1 300px;
            align-self: flex-start;
            position: sticky;
            top: 50px;
        }
    }

    .tab-btns-wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        border: 1px solid var(--lightgrey);
        border-radius: 4px;
    }

    .tab-btn {
        background-color: white;
        border: none;
        font-weight: 600;
        font-size: 0.9rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        flex: 1;
        border-radius: 4px;
        cursor: pointer;

        .small-length-indicator {
            background-color: none;
            color: white;
            border-radius: 4px;
            font-size: 0.8rem;
            margin-left: 0.6rem;
            border: 1px solid white;
            padding: 0.2rem 0.3rem 0.2rem 0.3rem;
        }
    }

    .tab-btn:not(.active) {
        color: var(--btn-blue-1);

        .small-length-indicator {
            color: var(--btn-blue-1);
            border: 1px solid var(--btn-blue-1);
        }
    }

    .tab-btn.active {
        background-color: var(--tier-color);
        color: white;

        .small-length-indicator {
            color: white;
            border: 1px solid white;
        }
    }

    .updated-wrapper {
        margin-top: 10px;

        .last-update {
            color: var(--grey);
            font-weight: 500;
            font-size: 0.9rem;
        }
    }

    .title-wrapper {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;
        
        .title-input-text {
            font-size: 1.6em;
            background: none;
            font-weight: 600;
            padding: 1px;
            border: none;
            width: 100%;
        }

        .title-input-text.unselected {
            padding: 1px;
            border: none;
        }
        
        .title-input-text:hover {
            border: 1px solid var(--lightgrey);
            padding: 0;
        }
    }

    .save-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .save-wrapper-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .autosave-wrapper {
        margin-right: 10px;

        .autosave {
            font-weight: 500;
        }
    }

    .submit-btn {
        margin-right: 0.6rem;
    }

    .submit-btn,
    .cancel-btn,
    .add-tier-btn,
    .clear-rankings-btn {
        border: none;
        padding: 10px;
        border-radius: 6px;
        font-weight: 600;
    }

    .submit-btn:not(.disabled),
    .cancel-btn:not(.disabled),
    .add-tier-btn:not(.disabled) {
        cursor: pointer;
    }

    .submit-btn:not(.disabled) {
        background-color: var(--submit-btn-light-1);
        color: white;
    }

    .submit-btn:not(.disabled):hover {
        background-color: var(--submit-btn-hover-light-1);
    }

    .cancel-btn,
    .submit-btn.disabled {
        background-color: var(--lightgrey);
    }

    .clear-rankings-btn {
        background-color: var(--red-1);
        color: white;
        cursor: pointer;
        margin-left: 0.4rem;
    }

    .add-tier-btn {
        background-color: var(--btn-green-1);
        color: white;
        font-size: 0.8rem;
    }

    .add-tier-btn:hover {
        background-color: var(--btn-green-2);
    }

    .drag-drop-rankings-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
    }

    .table-options-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .empty-description {
        padding: 0.5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        font-size: 1rem;

        .add-icon {
            font-size: 1rem;
            color: var(--btn-blue-1);
            margin: 0.3rem;
        }
    }

    .drag-drop-rankings {
        width: 100%;
        justify-content: center;

        .table-header {
            padding: 8px;
            font-size: 0.8rem;
            background-color: var(--lightgrey);
            border: 1px solid var(--lightgrey);
            border-radius: 6px 6px 0 0;
            padding: 0.7rem;

            .col-label {
                font-weight: 500;
                color: black;
            }

            .rank-wrapper {
                justify-items: flex-start;
                span {
                    width: 50%;
                }
            }
        }

        .draftable:last-child {
            border-bottom: 1px solid var(--lightgrey);
            border-radius: 0 0 6px 6px;
        }
    }

    .position-filter-wrapper {
        margin-bottom: 0.5rem;
    }

    .length-indicator {
        font-size: 0.8rem;
        padding: 0.3rem 0.4rem 0.3rem 0.4rem;
        border-radius: 6px;
        // background-color: var(--lightgrey);
        border: 1px solid var(--submit-btn-hover-light-1);
        color: var(--submit-btn-hover-light-1);
        margin-left: 0.6rem;
        margin-right: 0.6rem;
        font-weight: 600;
    }

    .description {
        margin-top: 0.6rem;
        margin-bottom: 0.4rem;
        color: black;
    }

    @media (max-width: 499px) {
        .edit-button {
            font-size: 9pt;
        }
        .last-update {
             font-size: 9pt;
        }
    }
}