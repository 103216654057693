.draft-page {
    main {
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;

        .draft-page-inner {
            display: flex;
            flex-direction: row;

            .draft-left {
                max-width: 75%;

                .draft-board-wrapper {
                    display: flex;
                    border: 1px solid var(--lightgrey);
                    border-radius: 6px;
                    overflow-x: auto;
                }
        
            }
            .draft-right {
                flex: 1;
            }
        }
    }
    .updated-wrapper {
        margin-top: 10px;

        .last-update {
            color: var(--grey);
            font-weight: 500;
            font-size: 0.9rem;
        }
    }

    .draft-content {
        display: flex;
        flex-direction: row;

    }

    .title-wrapper {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;
        
        .title-input-text {
            font-size: 1.6em;
            background: none;
            font-weight: 600;
            padding: 1px;
            border: none;
            width: 100%;
        }

        .title-input-text.unselected {
            padding: 1px;
            border: none;
        }
        
        .title-input-text:hover {
            border: 1px solid var(--lightgrey);
            padding: 0;
        }
    }
}