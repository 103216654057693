.contact-form {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--box-shadow-1);
    border-radius: 4px;
    padding: 10px;
    background-color: white;

    .input {
        margin: 10px;
        background-color: white;
    }
}

@media (max-width: 499px) {
    .contact-form {
        background: none;
        box-shadow: none;
    }
}