.draft-players {
    width: 100%;
    position: sticky;
    top: 60px;
    bottom: 0px;
    background-color: white;
    border: 1px solid var(--lightgrey);
    border-radius: 6px;

    .search-player-wrapper {
        border: 1px solid var(--lightgrey);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5rem;

        .search-player-input {
            border: none;
            outline: none;
        }

        .search-player-icon {
            margin-right: 0.5rem;
        }
    }

    .draft-players-btn-wrapper {
        border: 1px solid var(--lightgrey);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
    }

    .draft-players-btn {
        display: flex;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        flex: 1;
        font-weight: 600;
        border: none;
        outline: 2px solid var(--lightgrey);
        border-right: 2px solid var(--lightgrey);
    }

    .draft-players-btn:last-child {
        border-right: none;
        border-radius: 0 4px 4px 0;
    }

    .draft-players-btn:first-child {
        border-right: none;
        border-radius: 4px 0 0 4px;
    }

    .draft-players-btn.selected {
        background-color: var(--btn-blue-1);
        color: white;;
    }

    .draft-players-btn:not(.selected) {
        cursor: pointer;
        background-color: white;
    }

    .draft-players-btn:not(.selected):hover {
        background-color: var(--table-header-color);
    }

    .p {
        height: calc(100vh - 100px);
        overflow-y: auto;
    }
}