.draft-board {
    margin-top: 20px;
    border-radius: 6px;
    padding: 10px;


    .draft-board-header {
        width: 100%;
        white-space: nowrap;
        display: inline-block;
    }

    .draft-board-picks {
        display: flex;
        flex-direction: row;
    }
}