.labeled-select {
    padding-bottom: 1rem;
    label {
        display: block;
        font-size: 0.9rem;
        font-weight: 600;
        color: var(--dark-grey);
        margin-bottom: 4px;
    }
    .labeled-select-input {
        width: 100%;
        background-color: white;
    }
}