.rankings-page {
    width: 100%;

    h1 {
        margin-top: 0.6rem;
        margin-bottom: 0.5rem;
    }

    .description {
        margin-bottom: 0.8rem;
        color: black;
        line-height: 1.4rem;
        font-size: 0.9rem;
    }

    .createdAt-wrapper {
        display: flex;
        font-size: 0.8rem;
        color: var(--grey);
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

    .options-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .options-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        .select-filter,
        .position-filter,
        .edit-button {
            margin-bottom: 0.5rem;
        }

        .search,
        .position-filter,
        .edit-button {
            align-self: flex-end;
        }

        .edit-button {
            border: none;
            background-color: var(--btn-blue-1);
            color: white;
            cursor: pointer;
            padding: 0.7rem 0rem 0.7rem 0.9rem;
            border-radius: 6px;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
            font-weight: 600;
            box-shadow: var(--box-shadow-1);
            margin-left: 0.8rem;

            .new-icon {
                padding-left: 0.1rem;
                padding-right: 0.9rem;
                transition: 0.2s padding ease-in-out;
            }
        }

        .edit-button:hover {
            background-color: var(--submit-btn-hover-light-1);

            .new-icon {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
            }
        }
    }

    .rankings-table {
        width: 100%;
    }
}