.select-filter {

    label {
        display: block;
        font-weight: 600;
        color: var(--dark-grey);
        margin-bottom: 2px;
    }
    @media (min-width: 500px) {
        label {
            font-size: 11pt;
        }
        .select-filter-input {
            width: 160px;

            div {
                font-size: 11pt;
            }
        }
    }
    @media (max-width: 499px) {
        
        label {
            font-size: 9pt;
        }
        .select-filter-input {
            width: 120px;

            div {
                font-size: 9pt;
            }
        }
    }
}