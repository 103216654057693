.contact-page {
    h1 {
        margin-top: 0.5rem;
    }

    .description {
        margin-top: 20px;
        margin-bottom: 30px;
        line-height: 1.4em;
    }
    
    .submit-btn {
        background-color: var(--btn-blue-1);
        color: white;
        border-radius: 4px;
        padding: 12px;
        border: none;
        font-size: 11pt;
        font-weight: 600;
        cursor: pointer;
        margin: 10px;
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;

        div {
            height: 50%;
            display: flex;

            div {
                top: 0;
                height: 10px;
                width: 10px;
                font-size: 8pt;
            }
        }
    }

    .submit-btn:hover {
        background-color: var(--btn-blue-hover-1);
    }
}