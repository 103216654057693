@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: var(--inter-font);
}

html {
  background-color: var(--offwhite-1);
}

.App {
  background-color: var(--background-color);
  color: var(--text-color-1);
}

.App-dark {
  --background-color: var(--dark-grey);
  --text-color-1: var(--lightgrey);
  --google-btn-color: var(--google-blue);
}

.App-light {
  --background-color: var(--offwhite-1);
  --text-color-1: var(--black);
  --text-color-2: var(--white);
  --google-btn-color: var(--google-blue);
  --box-shadow-1: var(--box-shadow-light-1);
  --btn-blue-1: var(--submit-btn-light-1);
  --btn-blue-hover-1: var(--submit-btn-hover-light-1);
  --link-color-1: var(--link-color-light-1);
  --link-color-hover-1: var(--link-color-hover-light-1);
  --nav-color: var(--black);
  --table-header-color: var(--table-header-blue-light);
}

:root {
  --white: white;
  --offwhite-1: #F7FAFC;
  --black: black;
  --dark-grey: #1e1e1e;
  --grey: #454545;
  --lightgrey: #d4d4d4;
  --google-blue: #4285F4;
  --box-shadow-light-1: 0px 0px 10px 0px lightgray;
  --submit-btn-light-1: #635AFF;
  --tier-color: #0542dc;
  --submit-btn-hover-light-1: #3C54D5;
  --link-color-light-1: #635AFF;
  --link-color-hover-light-1: #1a1f35;
  --table-header-blue-light: #EAF0FF;
  --orbitron-font: 'Orbitron', sans-serif;
  --nunito-font: 'Nunito', 'sans-serif';
  --inter-font: 'Inter', 'sans-serif';
  --btn-green-1: #109e44;
  --btn-green-2: #388E3C;
  --red-1: #EB1600;
  --dark-red: #880808;
}

main {
  width: 800px;
  max-width: 94%;
  min-height: 75vh;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2vh;
}

h1 {
  font-size: 1.6em;
}

@media (min-width: 500px) {
  html {
    font-size: 12pt;
  }
}

@media (max-width: 499px) {
  html {
    font-size: 11pt;
  }
}

button, a {
  -webkit-tap-highlight-color: transparent;

}